<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 我的二维码
 * @Date: 2020-12-25 22:42:01
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-25 23:09:32
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/QRcode.vue
-->
<template>
  <div class="user_content">
    <!-- 我的二维码 -->
    <div class="qr_box">
      <div class="qr_hore"><img :src="avatar" alt=""></div>
      <div class="qr_name">{{ name }}</div>
      <div class="qr_img"><img :src="wxQr" alt=""></div>
      <div class="title">{{ title }}</div>
    </div>
    <!-- <img :src="bgImage" alt=""> -->
    <!-- <img :src="shareImg" alt=""> -->
  </div>
</template>

<script>
import {
  qrcodeV4
} from '@/services/userApi'

export default {
  data() {
    return {
      avatar: '',
      name: '',
      title: '',
      bgImage: '',
      url: '',
      wxQr: '',
      shareImg: ''
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      qrcodeV4().then(res => {
        if (Number(res.code) === 200) {
          this.avatar = res.avatar
          this.name = res.name
          this.title = res.title
          this.bgImage = res.bgImage
          this.url = res.url
          this.wxQr = res.wxQr
          this.shareImg = res.shareImg
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .qr_box {
    background: #fff;
    border-radius: 7px;
    margin: 13px;
    padding: 30px 0;

    .qr_hore {
      margin: auto;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .qr_name {
      color: #000;
      margin: 20px;
    }

    .qr_img {
      margin: auto;
      width: 120px;
      height: 120px;
      margin-top: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      margin-top: 15px;
    }
  }
}
</style>
